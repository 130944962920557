<template>
	<div>
		<SettingsChangeAccountType :current-account-type="setupInfo.accountType.value" />

		<SettingsChangeTalentPool />

		<SettingsChangeLocaleForm :current-locale="currentUser.locale" />

		<SettingsChangeEmailForm :current-email="currentUser.email" />

		<SettingsChangePasswordForm />

		<SettingsDeleteAccount />
	</div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
	metaInfo() {
		return {
			title: `${this.$t('nav.settings')} - ${this.$t('settings.account')}`,
		}
	},
	data() {
		return {
			loading: true,
		}
	},
	components: {
		SettingsChangeAccountType: () => import('@/components/settings/SettingsChangeAccountType.vue'),
		SettingsChangeTalentPool: () => import('@/components/settings/SettingsChangeTalentPool.vue'),
		SettingsChangeLocaleForm: () => import('@/components/settings/SettingsChangeLocaleForm.vue'),
		SettingsChangePasswordForm: () => import('@/components/settings/SettingsChangePasswordForm.vue'),
		SettingsChangeEmailForm: () => import('@/components/settings/SettingsChangeEmailForm.vue'),
		SettingsDeleteAccount: () => import('@/components/settings/SettingsDeleteAccount.vue'),
	},
	computed: {
		...mapGetters({
			currentUser: 'user/currentUser',
			setupInfo: 'user/setupInfo',
		}),
	},
	created() {
		this.fetchSetupInfo()
		this.fetchCurrentUser().then(() => {
			this.loading = false
		})
	},
	methods: {
		...mapActions('user', ['fetchCurrentUser', 'fetchSetupInfo']),
	},
}
</script>
